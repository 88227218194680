<template>
  <div class="city">
    <div class="container">
      <div class="city__title">Добавить город</div>
      <AddNewCity/>
    </div>
  </div>
</template>

<script>
import AddNewCity from '../components/AddNewCity'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'City',
  mixins: [AlertNotify],
  components: {
    AddNewCity
  },
  data() {
    return {
      isActiveInput: '',
      form: {
        name: '',
        absoluteTemperature: '',
        averageTemperature: '',
        northAirSpeed: '',
        northeastAirSpeed: '',

        eastAirSpeed: '',
        southeastAirSpeed: '',
        southAirSpeed: '',
        southwestAirSpeed: '',
        westAirSpeed: '',
        nortwestAirSpeed: '',
        calm: '',
      },
      rules: {
        name: [
          { required: true, trigger: 'blur' },
        ],
      }
    }
  },
}
</script>

<style lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.city {
  padding: 6rem 0 12rem;

  &__title {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.8rem;
    border-bottom: 2px solid $darkGrey;
    padding-bottom: 6.2rem;
  }

  &__form {
    &-button {
      margin-top: 6rem;
      display: flex;
      justify-content: center;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $lightGrey;
      padding: 2rem 0;
    }

    &-label {
      font-size: 1.4rem;
      line-height: 1.9rem;
    }

    &-input {
      width: 40rem;
    }

    &-text {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin: 3rem 0;
    }
  }
}
</style>
